import React, {Component} from "react";
import styled from 'styled-components';
import TextHeader from "../../TextHeader/TextHeader";
import Clearfix from "../../Clearfix/Clearfix";
import SelectableTextItem from "../../SelectableTextItem/SelectableTextItem";
import config from "../../../config.json";

const Container = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
  float: left;
`

const NavigationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  float: left;
`

const FilterButton = styled.button`
  position: absolute;
  top: -7px;
  left: -1px;
  border: none;
  background-color: white;
  padding: 8px 16px;
  padding-right: 54px;
  color: #153128;
  width: 149px;
  height: 38px;
  font-size: 18px;
  float: left;
  box-shadow: 0px -17px 40px #0000001A;
  z-index: 5;
  cursor: pointer;
  
  ${props => props.isSelected ? "" : "box-shadow: none;"}
  ${props => props.isSelected ? "" : "padding-left: 0;"}
  transition: 0.3s;

  @media (max-width: 600px) {
    left: -14px;
  }
`;

const BottomLine = styled.div`
  position: absolute;
  top: 39px;
  left: 62px;
  transform: translateX(-50%);
  width: 30px;
  height: 1.5px;
  background-color: #c9a386;
  z-index: 0;
  
  transition: 0.3s;
  ${props => props.isRolled ? "transform: translateX(-30px);" : ""}
`

const ArrowUp = styled.div`
  border: solid #153128;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4.5px;
  position: absolute;
  top: 42%;
  transform: rotate(-135deg);
  right: 16px;
`

const ArrowDown = styled.div`
  border: solid #153128;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4.5px;
  position: absolute;
  top: 28%;
  transform: rotate(45deg);
  right: 16px;
`

const FilterList = styled.div`
  position: absolute;
  top: 31px;
  left: 0;
  width: 246px;
  height: auto;
  background-color: white;
  padding: 20px 29px;
  cursor: auto;
  box-shadow: 0px 3px 30px #0000001A;
  z-index: 4;
  float: left;

  @media (max-width: 600px) {
    left: -14px;
  }
`

const FilterListItem = styled.div`
  font-size: 18px;
  padding: 4px 0;
  margin: 4px 0;
  cursor: pointer;
  position: relative;
  user-select: none;
  float: left;
  
  ${props => props.isSelected ? "font-weight: 600;" : ""}
`

const FilterListItemUnselectButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  right: -18px;
  font-weight: 600;
  
  ${props => !props.isVisible ? "display: none;" : ""}
`

class OfferNavigation extends Component {
    constructor() {
        super();
        this.state = {isListVisible: false, categories: [], categoriesSelected: []};
        //this.state.categories = ["pierścionki", "wisiorki", "krzyżyki", "kolczyki", "bransoletki", "naszyjniki", "spinki do makietów"];
    }

    componentDidMount() {
        this.loadCategories();
        this.loadFromLocalStorage();
    }

    loadCategories() {
        fetch(`${config.backendUrl}/api/v1/collections`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    console.log("error with loading categories...");
                } else {
                    let collectionsNames = [];
                    for (let i = 0; i < res.collections.length; i++) {
                        collectionsNames.push(res.collections[i].name);
                    }
                    this.setState({categories: collectionsNames});
                }

            })
            .catch(err => console.log(err))
    }

    toggleListVisibility = () => {
        this.setState({isListVisible: !this.state.isListVisible});
    }

    toggleCategorySelection(categoryNumber) {
        if (this.state.categoriesSelected.includes(categoryNumber)) {
            this.state.categoriesSelected.splice(this.state.categoriesSelected.indexOf(categoryNumber), 1);
        } else {
            this.state.categoriesSelected.push(categoryNumber);
        }
        this.setState({categoriesSelected: this.state.categoriesSelected})
        console.log(this.state.categoriesSelected)
        this.props.parent.setState({filters: this.state.categoriesSelected}, () => {
            this.saveToLocalStorage();
        });
    }

    saveToLocalStorage() {
        localStorage.setItem("filters", JSON.stringify(this.state.categoriesSelected));
    }

    loadFromLocalStorage() {
        let categoriesSelected = localStorage.getItem("filters");
        if (!categoriesSelected) return;
        try {
            categoriesSelected = JSON.parse(categoriesSelected);
        } catch (e) {
            return;
        }

        this.setState({categoriesSelected: categoriesSelected});
        this.props.parent.setState({filters: categoriesSelected});
    }

    isFilterItemSelected(categoryNumber) {
        return this.state.categoriesSelected.includes(categoryNumber);
    }

    render() {
        const { categories } = this.state;
        return <Container>
            <NavigationContainer>
                <FilterButton isSelected={this.state.isListVisible} onClick={() => this.toggleListVisibility()}>
                    KOLEKCJE {this.state.isListVisible ? <ArrowUp /> : <ArrowDown />}
                </FilterButton>
                {this.state.categoriesSelected.length > 0 ? <BottomLine isRolled={!this.state.isListVisible} /> : ''}

                <Clearfix/>
                {
                    this.state.isListVisible ?
                    <FilterList>
                        {
                            categories.map(
                                (category, index) => <>
                                    <FilterListItem
                                        isSelected={this.isFilterItemSelected(index)}
                                        onClick={() => this.toggleCategorySelection(index)}>{category}
                                        <FilterListItemUnselectButton isVisible={this.isFilterItemSelected(index)}>x</FilterListItemUnselectButton>
                                    </FilterListItem>
                                    <Clearfix/>
                                </>
                            )
                        }
                    </FilterList>
                    : ""
                }
            </NavigationContainer>
            <Clearfix/>
        </Container>
    }
}

export default OfferNavigation;