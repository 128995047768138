import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  width: 411px;
  height: 200px;
  //background-color: aqua;
  margin: 20px;
  float: left;
`

const OfferEmptyElement = () => {
    return <Container>

    </Container>
}

export default OfferEmptyElement;