import React from "react";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import missingPhoto from '../../Product/missing_photo.png'

const Container = styled.div`
  //width: 411px;
  width: calc((1/4)*100% - 60px);
  height: auto;
  //background-color: silver;
  margin: 40px 30px;
  float: left;

  @media (max-width: 1000px) {
    width: calc((1/2)*100% - 60px);
  }

  @media (max-width: 600px) {
    width: calc((1/2)*100% - 32px);
    margin: 40px 16px;
  }
`

const Photo = styled.img`
  width: 100%;
  //height: 308px;
  background-color: black;
  transition: 0.3s;
  &:hover {
    filter: brightness(90%);
  }
`

const Title = styled.p`
  margin-top: 23px;
  margin-bottom: 10px;
  //font: normal normal normal 20px/24px Inter;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
  
`

const Subtitle = styled.p`
  margin-top: 0;
  //font: normal normal normal 15px/19px Inter;
  font-size: 15px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`

const OfferElementTall = props => {
    const { productId, photoThumbnail, name, making } = props.data;
    return <Link to={"/produkt/" + productId}>
        <Container>
            <Photo src={ (photoThumbnail ? photoThumbnail : missingPhoto) } />
            <Title>{name}</Title>
            <Subtitle>{making}</Subtitle>
        </Container>
    </Link>
}

export default OfferElementTall;