import { Component } from "react";
import TextHeader from "../TextHeader/TextHeader";
import styled from "styled-components";
import Button from "../Button/Button";
import Clearfix from "../Clearfix/Clearfix";
import config from "../../config.json";

const Container = styled.div`
  width: 100%;
  height: auto;
  padding-left: 50%;
`;

const Wrapper = styled.div`
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  min-width: 500px;

  @media (max-width: 450px) {
    min-width: 300px;
  }

  @media (max-width: 300px) {
    min-width: 260px;
  }

  @media (max-width: 260px) {
    min-width: 200px;
  }
`;

const CodeInputCell = styled.input`
  font-size: 32px;
  text-align: center;
  width: 40px;
  height: 50px;
  border: 0;
  border-bottom: 2px solid #c9a386;
  margin: 8px 6px;
  outline: none;

  @media (max-width: 600px) {
    font-size: 24px;
  }
  
  @media (max-width: 260px) {
    width: 20px;
    height: 25px;
    font-size: 17px;
  }
`;

class AccessRestrictionPanel extends Component {
    constructor() {
        super();
        this.state = {isIncorrectWarning: false, numberOfCells: 5, cellValues: []}
        while (this.state.cellValues.length < this.state.numberOfCells) this.state.cellValues.push("");
    }
    componentDidMount() {

    }

    handleFocus = (index, event) => {
        if (event.target.value.length > 1) {
            if (this.state.cellValues[index] === event.target.value[0]) {
                event.target.value = event.target.value[1];
            } else {
                event.target.value = event.target.value[1];
            }
            let newCellValues = this.state.cellValues;
            newCellValues[index] = event.target.value;

            this.setState({cellValues: newCellValues});
            return;
        }
        if (event.target.value === " ") {
            event.target.value = "";
            return;
        }
        let newCellValues = this.state.cellValues;
        newCellValues[index] = event.target.value;

        this.setState({cellValues: newCellValues});
        if (index < this.state.numberOfCells - 1) {
            this[`cell-${index+1}`].focus();
        }
    }
    getInputCode() {
        let code = "";
        for (let i = 0; i < this.state.cellValues.length; i++) {
            code += this.state.cellValues[i];
        }
        return code;
    }
    checkAccess() {
        const code = this.getInputCode();
        fetch(`${config.backendUrl}/api/v1/check_code?code=${code}`)
            .then(res => res.json())
            .then(res => {
                if (res.status !== "success") return alert("Problem z połączeniem, spróbuj ponownie później.");
                if (res.accessGranted) {
                    this.handleAccessGrant(code);
                } else {
                    this.setState({isIncorrectWarning: true});
                    for (let i = 0; i < this.state.numberOfCells; i++) {
                        this[`cell-${i}`].value = "";
                    }
                    this[`cell-0`].focus();
                }
            })
            .catch(err => console.log(err))
    }
    handleAccessGrant(code) {
        console.log("Access granted!");
        //localStorage.setItem("accessCode", code);
        window.location.reload(false);
    }


    render() {


        return(
            <Container>
                <Wrapper>
                    {
                        !this.state.isIncorrectWarning
                        ?
                            <>
                            <TextHeader style={{lineHeight: 1.5}}>DOSTĘP DO KATALOGU JEST ZABLOKOWANY</TextHeader>
                            <p>Wprowadź kod dostępu i sprawdź naszą ofertę</p>
                            </>
                        :
                            <>
                                <TextHeader style={{color: "red"}}>NIEPRAWIDŁOWY KOD DOSTĘPU</TextHeader>
                                <p>Spróbuj ponownie</p>
                            </>
                    }

                    {this.state.cellValues.map((inputCell, index) => <CodeInputCell autoComplete="off" maxLength="2" ref={input => this[`cell-${index}`] = input} key={index} id={`cell-${index}`} onChange={(e) => this.handleFocus(index, e)} />)}
                    <Clearfix />
                    <br />
                    <Button onClick={() => this.checkAccess()}>DALEJ</Button>
                </Wrapper>
            </Container>
        )
    }
}

export default AccessRestrictionPanel;