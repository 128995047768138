import styled from 'styled-components';
import Header from "../../components/Header/Header";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import TextHeader from "../../components/TextHeader/TextHeader";
import Footer from "../../components/Footer/Footer";
import React, {Component} from "react";
import AccessRestrictionPanel from "../../components/AccessRestrictionPanel/AccessRestrictionPanel";
import OfferList from "../../components/Offer/OfferList/OfferList";
import config from "../../config.json";

class OfferView extends Component {
    constructor() {
        super();
        this.state = {isLoaded: false, isAccess: false, isError: false};
    }
    componentDidMount() {
        this.checkAccess();
    }

    checkAccess() {
        fetch(`${config.backendUrl}/api/v1/check_access`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    // nothing
                    this.setState({isLoaded: true, isError: true});
                } else {
                    if (res.access) {
                        this.setState({isLoaded: true, isAccess: true});
                    } else {
                        this.setState({isLoaded: true, isAccess: false});
                    }
                }

            })
            .catch(err => console.log(err))
    }
    render() {
        return <>
            <Header selectedIndex={2} />
            <ContentContainer>
                {!this.state.isLoaded
                    ? "Ładowanie..."
                    : (
                        this.state.isError ? "Wystąpił błąd, spróbuj ponownie później lub skontaktuj się z nami."
                            :
                            (!this.state.isAccess ?
                                    <AccessRestrictionPanel />
                                    :
                                    <OfferList />
                            )
                    )
                }
            </ContentContainer>
            <Footer selectedIndex={2} />
        </>
    }
}

export default OfferView;