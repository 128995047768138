import styled from 'styled-components';
import Header from "../../components/Header/Header";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import TextHeader from "../../components/TextHeader/TextHeader";
import Clearfix from "../../components/Clearfix/Clearfix";
import Footer from "../../components/Footer/Footer";
import React from "react";
import photo from "./kontakt.jpg";

const Section = styled.div`
  width: calc(50% - 245px);
  //border: 1px solid black;
  ${props => props.paddingLeft ? 'padding-left: 30px;' : ''}
  ${props => props.paddingLeft ? 'width: calc(50% - 325px);' : ''}
  
  @media only screen and (max-width: 1320px) {
    width: 100%;
    padding-left: 0;
  }
  
  float: left;

  @media (max-width: 1320px) {
    margin-bottom: 40px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
  }
`

const Table = styled.table`
  width: 100%;
`

const Td = styled.td`
  padding: 4px 0;
  
  ${props => props.bold ? 'font-weight: 600;' : ''}
  ${props => props.bold ? 'width: 105px;' : ''}
`

const PhotosSection = styled.div`
  width: 550px;
  height: 400px;
  padding-left: 0px;
  margin-left: 20px;
  float: left;

  @media (min-width: 1000px) and (max-width: 1320px) {
    position: absolute;
    width: 380px;
    top: 0;
    right: 100px;
  }
  
  @media (max-width: 1000px) {
    //display: none;
    width: 100%;
    max-width: 600px;
    margin-left: 0px;
    float: none;
  }
`;

const Photo = styled.img`
  width: 500px;
  margin: 0 25px;
  margin-right: 0;
  background-color: white;
  float: right;

  @media (max-width: 1320px) {
    margin: 0;
    width: 100%;
    height: auto;
    float: left;
  }
`;

const ContactView = () => {
    return <>
        <Header selectedIndex={1} />
        <ContentContainer>
            <Section>
                <TextHeader bottomLine style={{marginTop: 0}}>ADRES FIRMY</TextHeader>
                <span style={{fontWeight: 600}}> ARTSTYLMS Maciej Szymczak</span>
                <p style={{marginTop: 10, marginBottom: 30}}>ul. Migowska 10, 80-287 Gdańsk (POLSKA)</p>
                <Table>
                    <tr>
                        <Td bold>NIP:</Td>
                        <Td>581070705</Td>
                    </tr>
                    <tr>
                        <Td bold>REGON:</Td>
                        <Td>192728623</Td>
                    </tr>
                    <tr>
                        <Td bold>NR KONTA:</Td>
                        <Td>29 1050 1764 1000 0092 2678 5906</Td>
                    </tr>
                    <tr>
                        <Td bold>BANK:</Td>
                        <Td>ING Bank</Td>
                    </tr>
                </Table>
            </Section>
            <Section paddingLeft>
                <TextHeader bottomLine style={{marginTop: 0}}>KONTAKT</TextHeader>
                <Table>
                    <tr>
                        <Td bold>Tel:</Td>
                        <Td>(+48) 881 059 186</Td>
                    </tr>
                    <tr>
                        <Td bold>Email:</Td>
                        <Td>biuro@artstylms.pl</Td>
                    </tr>
                    <tr>
                        <Td bold>Facebook:</Td>
                        <Td>facebook.com/artstyl</Td>
                    </tr>
                    <tr>
                        <Td bold>Instagram:</Td>
                        <Td>@artstyl</Td>
                    </tr>
                </Table>
            </Section>
            <PhotosSection>
                <Photo src={photo} />
            </PhotosSection>
        </ContentContainer>
        <Footer selectedIndex={1} />
    </>
}

export default ContactView;