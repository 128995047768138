import styled from 'styled-components';
import Header from "../../components/Header/Header";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import TextHeader from "../../components/TextHeader/TextHeader";
import Footer from "../../components/Footer/Footer";
import React from "react";

import photo from "./o-nas-photo.jpg";

const TextSection = styled.div`
  width: calc(100% - 600px);
  padding-right: 80px;
  float: left;

  @media only screen and (max-width: 1320px) {
    width: 100%;
    padding-left: 0;
  }

  float: left;

  @media (max-width: 1320px) {
    margin-bottom: 40px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
  }
`;

const PhotosSection = styled.div`
  width: 600px;
  height: 400px;
  padding-left: 50px;
  float: left;


  @media (max-width: 1320px) {
    //display: none;
    width: 100%;
    max-width: 600px;
    padding-left: 0px;
    float: none;
  }
`;

const Photo = styled.img`
  width: 500px;
  margin: 0 25px;
  margin-right: 0;
  background-color: white;
  float: right;

  @media (max-width: 1320px) {
    margin: 0;
    width: 100%;
    height: auto;
    float: left;
  }
`;

const AboutUsView = () => {
    return <>
        <Header selectedIndex={0} />
        <ContentContainer>
            <TextSection>
                <TextHeader bottomLine style={{marginTop: 0}}>O NAS</TextHeader>
                <span style={{fontWeight: 600}}> ARTSTYLMS - Biżuteria z bursztynu</span>
                <p style={{lineHeight: 1.9, marginTop: 17}}>
                    Jesteśmy firmą powstałą w 1999 roku. Od początku zajmujemy się wytwarzaniem unikalnej biżuterii srebrnej. Biżuterię, którą od podstaw produkujemy w naszej pracowni, łączymy z najwyższej jakości bursztynem bałtyckim. Aby sprostać wymaganiom klienta poszerzyliśmy asortyment o biżuterię odlewaną. Nadążając za zmieniającymi się trendami, rozwijamy ofertę o nowe wzory. Nasze bogate wzornictwo zadowoli najbardziej wymagającego klienta.
                    <br />Zapraszamy do współpracy!
                </p>
            </TextSection>
            <PhotosSection>
                <Photo src={photo} />
            </PhotosSection>
        </ContentContainer>
        <Footer selectedIndex={0} />
    </>
}

export default AboutUsView;