import React, {Component} from "react";
import styled from 'styled-components';
import OfferElement from "../OfferElement/OfferElement";
import OfferEmptyElement from "../OfferEmptyElement/OfferEmptyElement";
import OfferNavigation from "../OfferNavigation/OfferNavigation";
import Clearfix from "../../Clearfix/Clearfix";
import config from '../../../config.json'
import OfferElementTall from "../OfferElementTall/OfferElementTall";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  //background-color: gray;
  float: left;
`

const SubContainer = styled.div`
  margin: 0 -30px;

  @media (max-width: 600px) {
    margin: 0 -15px;
  }
`


class OfferShortList extends Component {
    constructor(props) {
        super(props);
        this.state = {products: []};
    }

    componentDidMount() {
        const productIds = this.props.productIds;
        const code = 0;

        for (let i = 0; i < productIds.length; i++) {
            fetch(`${config.backendUrl}/api/v1/product?productId=${productIds[i]}&code=${code}`)
                .then(res => res.json())
                .then(res => {
                    if (res.status === "error") {
                        // nothing
                    } else {
                        const {product} = res;
                        let newProductsArray = this.state.products;
                        newProductsArray.push({productId: product.productId, name: product.name, photoThumbnail: product.photoThumbnail, making: product.making})
                        this.setState({products: newProductsArray});
                    }

                })
                .catch(err => console.log(err))
        }
    }

    render() {
        return <Container>
            <SubContainer>
                {
                    this.state.products.map(product => this.props.tall ? <OfferElementTall data={product} /> : <OfferElement data={product} />)
                }
            </SubContainer>
        </Container>
    }
}

export default OfferShortList;