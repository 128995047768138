import styled from 'styled-components';

const Container = styled.button`
  min-width: 144px;
  padding: 8px 25px;
  min-height: 38px;
  font-size: 18px;
  border: 0;
  background-color: #153128;
  margin: 8px;
  color: white;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  
  &:hover {
    
  }
  
`;

const Button = props => {
    return (
        <Container onClick={props.onClick} style={props.style}>
            {props.children}
        </Container>
    )
}

export default Button;