import styled from 'styled-components';

const Container = styled.div`
  font-size: 18px;
  ${props => props.bottomLine ? 'padding-bottom: 20px;' : ''}
  margin-top: 32px;
  ${props => props.bottomLine ? 'margin-bottom: 20px;' : ''}
  position: relative;
  color: #153128;
`;

const BottomLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 1.5px;
  background-color: #c9a386;
`

const BottomArrowBack = styled.div`
  position: absolute;
  bottom: -4px;
  left: 2px;
  border: solid #c9a386;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
`

const TextHeader = props => {
    return (
        <Container bottomLine={props.bottomLine || props.bottomArrowBack} style={props.style}>
            {props.children}
            {props.bottomLine || props.bottomArrowBack ? <BottomLine /> : ''}
            {props.bottomArrowBack ? <BottomArrowBack /> : ''}
        </Container>
    )
}

export default TextHeader;