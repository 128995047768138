import React, {Component} from "react";
import styled from 'styled-components';
import OfferElement from "../OfferElement/OfferElement";
import OfferEmptyElement from "../OfferEmptyElement/OfferEmptyElement";
import OfferNavigation from "../OfferNavigation/OfferNavigation";
import Clearfix from "../../Clearfix/Clearfix";
import config from "../../../config.json";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 120px - 335px);
  //background-color: gray;
  float: left;
`

const SubContainer = styled.div`
  margin: 0 -30px;
  //background-color: olivedrab;

  @media (max-width: 600px) {
    margin: 0 -15px;
  }
`

const NoProductsInformation = styled.div`
  margin: 75px 30px;  
`


class OfferList extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoaded: false, isError: false, filters: [], products: []};
    }

    componentDidMount() {
        fetch(`${config.backendUrl}/api/v1/products`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    this.setState({isLoaded: true, isError: true});
                    if (res.message === "no_product") this.setState({errorMessage: "Nie znaleziono produktów."});
                    return;
                } else {
                    let products = [];
                    for (let i = 0; i < res.products.length; i++) {
                        if (!res.products[i].isPhotoTall) products.push(res.products[i])
                    }
                    this.setState({isLoaded: true, isError: false, products: products});
                }

            })
            .catch(err => console.log(err))
    }

    render() {
        let productsFoundCounter = 0;
        if (this.state.filters.length === 0) {
            productsFoundCounter = this.state.products.length;
        } else {
            for (let i = 0; i < this.state.products.length; i++) {
                if (this.state.filters.includes(this.state.products[i].collectionId)) {
                    productsFoundCounter++;
                }
            }
        }

        return <Container>
            {
                !this.state.isLoaded
                    ?
                        ""
                    :
                        (
                            this.state.isError
                                ?
                                    "Błąd podczas ładowania listy."
                                :
                                    <>
                                        <OfferNavigation parent={this} />
                                        <Clearfix/>
                                        <br />
                                        <SubContainer>
                                            {
                                                this.state.products.map(product =>
                                                    (this.state.filters.length === 0 || this.state.filters.includes(product.collectionId)
                                                    ?
                                                    <OfferElement data={product} />
                                                    : "")
                                                )
                                            }


                                            {
                                                productsFoundCounter === 0
                                                ?
                                                    <NoProductsInformation><p><b>Nie znaleziono produktów.</b></p>Spróbuj zmienić filtry wyszukiwania lub skontaktuj się z nami.</NoProductsInformation>
                                                :
                                                    ''
                                            }

                                        </SubContainer>
                                    </>
                        )
            }
        </Container>
    }
}

export default OfferList;