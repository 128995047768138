import React from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import AboutUsView from "./views/AboutUsView/AboutUsView";
import ContactView from "./views/ContactView/ContactView";
import Footer from "./components/Footer/Footer";
import OfferView from "./views/OfferView/OfferView";
import IndexView from "./views/IndexView/IndexView";
import ProductView from "./views/ProductView/ProductView";

export default function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<IndexView />} />
            <Route path="/o-nas" element={<AboutUsView />} />
            <Route path="/kontakt" element={<ContactView />} />
            <Route path="/oferta" element={<OfferView />} />
            <Route exact path="/produkt/:productId" element={<ProductView />} />
        </Routes>
    </BrowserRouter>
  );
}
