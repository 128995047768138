import { React, Component } from "react";
import styled from "styled-components";
import photo1 from "./product.jpg";
import TextHeader from "../TextHeader/TextHeader";
import WeightLabel from "./WeightLabel/WeightLabel";
import Clearfix from "../Clearfix/Clearfix";
import Button from "../Button/Button";
import SeparationLine from "../SeparationLine/SeparationLine";
import {Link} from "react-router-dom";
import config from '../../config.json'
import missingPhoto from './missing_photo.png';

import Magnifier from "react-magnifier";
import OfferBelowProductList from "../Offer/OfferBelowProductList/OfferBelowProductList";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  float: left;
`

const PhotoSection = styled.div`
  width: 600px;
  height: auto;
  float: left;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    width: 400px;
  }
`
const Photo = styled.img`
  width: 100%;
  min-height: 400px;
  background-color: black;
`

const InformationSection = styled.div`
  width: calc(100% - 600px - 125px);
  height: 1px;
  min-height: 400px;
  margin-left: 125px;
  float: left;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    float: none;
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    width: calc(100% - 400px - 125px);    
  }
`

const DescriptionArea = styled.div`
  width: 100%;
  min-height: calc(100% - 200px);
  float: left;

  @media (max-width: 1000px) {
    min-height: 0;
    margin-top: 30px;
  }
`

const BoldedDescriptionParagraph = styled.p`
  font-weight: 600;
`

const DescriptionParagraph = styled.p`
    
`

const DetailsArea = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  float: left;
`

const ProductCode = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: 1000px) {
    position: static;
  }
`

const Separator = styled.div`
  width: 100%;
  height: 160px;

  @media (max-width: 1000px) {
    height: 0px;
  }
`

class Product extends Component {
    constructor() {
        super();
        this.state = {isLoaded: false, isError: false, errorMessage: null, name: null, photoUrl: null, weight: null, making: null, descriptionBold: null, description: null};
    }

    componentDidMount() {
        const productId = this.props.productId;
        const code = 0;
        fetch(`${config.backendUrl}/api/v1/product?productId=${productId}&code=${code}`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    this.setState({isLoaded: true, isError: true});
                    if (res.message === "no_product") this.setState({errorMessage: "Nie znaleziono takiego produktu."});

                    return;
                } else {
                    const { product } = res;
                    this.setState({isLoaded: true, name: product.name, photoNormal: (product.photoNormal ? product.photoNormal : missingPhoto), productCode: product.productCode, weight: product.weight, making: product.making, descriptionBold: product.descriptionBold, description: product.description})
                }

            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <Container>
                {
                    !this.state.isLoaded
                        ? ""
                        :
                            (this.state.isError
                                    ? (this.state.errorMessage ? this.state.errorMessage : "Problem z połączeniem, spróbuj ponownie później lub skontaktuj się z nami.")
                                    :
                                    <>
                                        <PhotoSection>
                                            <Magnifier src={this.state.photoNormal} mgBorderWidth={0}  />
                                        </PhotoSection>
                                        <InformationSection>
                                        <DescriptionArea>
                                            <TextHeader style={{fontSize: 20, marginTop: 0, marginBottom: 6}}>{this.state.name}</TextHeader>
                                            <span style={{fontSize: 15}}>{this.state.making}</span>
                                            <SeparationLine marginTop="16" marginBottom="16" />
                                            <BoldedDescriptionParagraph>{this.state.descriptionBold}</BoldedDescriptionParagraph>
                                            <DescriptionParagraph>{this.state.description}</DescriptionParagraph>
                                        </DescriptionArea>
                                        <DetailsArea>
                                            <WeightLabel value={this.state.weight} />
                                            <Clearfix />
                                            <Link to="/kontakt"><Button style={{marginLeft: 0}}>SPYTAJ O DOSTĘPNOŚĆ</Button></Link>
                                            <ProductCode>Kod produktu: <span style={{fontWeight: 600}}>{this.state.productCode}</span></ProductCode>
                                        </DetailsArea>
                                        </InformationSection>
                                        <Clearfix />
                                        <Separator />
                                        <TextHeader bottomLine style={{marginBottom: 0}}>POLECANE</TextHeader>
                                        <OfferBelowProductList ignoreProductId={this.props.productId} />
                                    </>
                            )
                }
            </Container>
        )
    }
}

export default Product;