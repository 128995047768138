import styled from 'styled-components';
import {Link} from "react-router-dom";
import Clearfix from "../Clearfix/Clearfix";
import config from "../../config.json";

const Container = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  float: left;
`;

const SubContainer = styled.div`
  width: calc(100% - 0px);
  max-width: calc(1600px + 2*300px);
  margin-bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  color: black;
  padding: 56px 300px;
  font-size: 15px;
  float: left;

  @media (max-width: 1685px) {
    padding: 56px 180px;
    max-width: calc(1600px + 2*180px);
  }
  @media (max-width: 1455px) {
    padding: 56px 100px;
    max-width: calc(1600px + 2*100px);
  }

  @media (max-width: 600px) {
    padding: 28px 30px;
  }
`;

const UpperSection = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  line-height: 1.4;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const UpperSectionSegment = styled.div`
  min-width: 200px;
  margin-right: 40px;
  float: left;

  @media (max-width: 600px) {
    min-width: 0;
    width: 50%;
    margin-right: 0;

  }
`
const UpperSectionSegmentTitle = styled.p`
  font-weight: bold;
  @media (max-width: 600px) {
    margin: 12px 0;
  }
`
const UpperSectionSegmentParagraph = styled.p`
  margin: 8px 0;
  @media (max-width: 600px) {
    margin: 9px 0;
  }
`
const UpperSectionSegmentSelectionLine = styled.div`
  width: 16px;
  height: 1px;
  background-color: black;
  margin-top: -5px;
`

const NewsletterInput = styled.input`
  width: 225px;
  height: 44px;
  padding: 16px;
  border: 0;
  outline: none;
  margin-top: 7px;

  @media (max-width: 1100px) {
    float: left;
  }

  @media (max-width: 600px) {
    width: calc(100% - 48px);
  }
`;

const NewsletterSendButton = styled.button`
  width: 44px;
  height: 44px;
  background-color: black;
  color: white;
  margin: 0;
  border: 0;
  margin-top: 7px;
  font-size: 32px;
  font-weight: lighter;
  font-family: 'Inter', sans-serif;
  padding-bottom: 6px;
  cursor: pointer;
  
  float: left;

  @media (min-width: 1100px) {
    display: none;
  }
`

const BottomSection = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
`;

const BottomSectionItem = styled.div`
  border-right: 1px solid black;
  padding-right: 21px;
  margin-right: 21px;
  font-size: 14px;
  float: left;
  
  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  @media (min-width: 601px) and (max-width: 1100px) {
    line-height: 2;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-right: none;
    padding: 4px 0;
  }
  
`;

const Footer = props => {
    const sendNewsletterJoinRequest = () => {
        const value = document.getElementById('newsletter-input').value;
        fetch(`${config.backendUrl}/api/v1/subscribe_newsletter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: value})
        })
            .then(res => res.json())
            .then(res => {
                if (res.status !== "success") return console.log("Email jest niepoprawny lub problem z połączeniem, spróbuj ponownie później.");

                document.getElementById('newsletter-input').value = '';
            })
            .catch(err => console.log(err))
    }
    return (
        <Container>
            <SubContainer>
                <UpperSection>
                    <UpperSectionSegment>
                        <UpperSectionSegmentTitle>Zakładki</UpperSectionSegmentTitle>
                        <Link to="/o-nas"><UpperSectionSegmentParagraph>o nas</UpperSectionSegmentParagraph></Link>
                        {props.selectedIndex === 0 ? <UpperSectionSegmentSelectionLine /> : ""}
                        <Link to="/kontakt"><UpperSectionSegmentParagraph>kontakt</UpperSectionSegmentParagraph></Link>
                        {props.selectedIndex === 1 ? <UpperSectionSegmentSelectionLine /> : ""}
                        <Link to="/oferta"><UpperSectionSegmentParagraph>oferta</UpperSectionSegmentParagraph></Link>
                        {props.selectedIndex === 2 ? <UpperSectionSegmentSelectionLine /> : ""}
                    </UpperSectionSegment>
                    <UpperSectionSegment>
                        <UpperSectionSegmentTitle>Dołącz do nas</UpperSectionSegmentTitle>
                        <UpperSectionSegmentParagraph>Zapisz się do newslettera<br /> i otrzymuj informacje o nowej ofercie</UpperSectionSegmentParagraph>
                        <NewsletterInput id={`newsletter-input`} placeholder="e-mail" onKeyDown={(e) => e.keyCode === 13 ? sendNewsletterJoinRequest() : null} />
                        <NewsletterSendButton onClick={() => sendNewsletterJoinRequest()}>></NewsletterSendButton>
                    </UpperSectionSegment>
                </UpperSection>
                <Clearfix />
                <BottomSection>
                    <BottomSectionItem>email: biuro@artstylms.pl</BottomSectionItem>
                    <BottomSectionItem>tel: (+48) 881 059 186</BottomSectionItem>
                    <BottomSectionItem>ul. Migowska 10, 80-287 Gdańsk</BottomSectionItem>
                </BottomSection>
            </SubContainer>

        </Container>
    )
}

export default Footer;