import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  height: 50px;
  float: left;
`

const Value = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: #153128;
  margin-right: 5px;
`

const Subtext = styled.span`
  font-size: 15px;
  color: #153128;
`

const WeightLabel = props => {
    return <Container>
        <Value>{props.value} g</Value>
        <Subtext>netto</Subtext>
    </Container>
}

export default WeightLabel;