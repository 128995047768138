import styled from 'styled-components';

const Clearfix = styled.div`
  display: table;
  clear: both;
  content: '';

  &:after {
    content: '';
  }
  &:before {
    content: '';
  }
`;

export default Clearfix;