import styled from 'styled-components';
import {Link} from "react-router-dom";

const Container = styled.div`
  width: calc(100% - 0px);
  max-width: calc(1600px + 2*300px);
  
  height: auto;
  min-height: calc(100vh - 120px - 2*100px - 240px);
  margin: 100px 0;
  margin-left: 50%;
  transform: translateX(-50%);
  //padding: 0 50px;
  padding: 0 300px;
  font-size: 15px;
  float: left;

  @media (max-width: 1685px) {
    padding: 0 180px;
    max-width: calc(1600px + 2*180px);
  }
  @media (max-width: 1455px) {
    padding: 0 100px;
    max-width: calc(1600px + 2*100px);
  }

  @media (max-width: 1320px) {
    margin-top: 60px;
  }

  @media (max-width: 600px) {
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 0 30px;
    max-width: calc(1600px + 2*30px);
  }
`;

const ContentContainer = props => {
    return (
        <Container style={props.style}>
            {props.children}
        </Container>
    )
}

export default ContentContainer;