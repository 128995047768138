import styled from 'styled-components';
import React from "react";
import photo1 from "./contact-us.jpg";
import Button from "../../Button/Button";
import {Link} from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  float: left;
`
const Image =  styled.img`
  width: 100%;
  height: 320px;
  object-fit: cover;
  object-position: 0 -calc(350px * 50%);
  float: left;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 300px;
  @media (max-width: 1685px) {
    left: 180px;
  }
  @media (max-width: 1455px) {
    left: 100px;
  }
  transform: translateY(-50%);

  @media (max-width: 600px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 14px;
    width: 300px;
  }
`

const IndexContactBanner = () => {
    return <Container>
        <Image src={photo1} />
        <ButtonContainer>
            <Link to="/kontakt"><Button style={{margin: 0}}>SKONTAKTUJ SIĘ Z NAMI</Button></Link>
        </ButtonContainer>
    </Container>
}

export default IndexContactBanner;