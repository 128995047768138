import styled from 'styled-components';
import React, {Component} from "react";
import photo1 from "./1.jpg";
import photo2 from "./2.jpg";
import photo3 from "./3.jpg";
import photo4 from "./4.jpg";
import photo5 from "./5.jpg";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  background-color: #153128;
  // background-image: url("${photo1}");
  // background-repeat: round;
  position: relative;
  float: left;

  @media (max-width: 1100px) {
    height: calc(65vh);
  }

  @media (max-width: 800px) {
    height: calc(50vh);
  }
  @media (max-width: 400px) {
    height: calc(35vh);
  }
`
const Image =  styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px); // header = 120px
  object-fit: cover;
  
  opacity: ${props => props.visible ? "1" : "0"};
  z-index: ${props => props.zIndex ? "1" : "0"};
  transition: opacity 1.25s;

  @media (max-width: 1100px) {
    height: calc(65vh);
  }

  @media (max-width: 800px) {
    height: calc(50vh);
  }
  @media (max-width: 400px) {
    height: calc(35vh);
  }
`

const SliderNavigationContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 45px;
  width: 20px;
  height: 250px;

  @media (max-width: 1100px) {
    right: 25px;
  }

  @media (max-width: 800px) {
    top: 60%;
  }
`

const Square = styled.div`
  width: ${props => props.selected ? "20px" : "10px"};
  height: ${props => props.selected ? "20px" : "10px"};
  margin: 20px 0;
  ${props => props.selected ? "" : "margin-left: 5px;"};
  background-color: ${props => props.selected ? "#C9A386" : "#153128"};
  //opacity: ${props => props.selected ? "1" : "0.3"};
  cursor: pointer;
`

class IndexSlider extends Component {
    constructor() {
        super();
        this.state = {photos: [photo1, photo2, photo3, photo4, photo5], visibleIndex: 0, isChangingBlocked: false};
    }
    componentDidMount() {
        setInterval(() => {
            if (this.state.isChangingBlocked) {
                this.setState({isChangingBlocked: false});
            } else {
                this.setState({visibleIndex: (this.state.visibleIndex + 1) % (this.state.photos.length)})
            }
        }, 6000)
    }

    render() {
        return <Container>
            {
                this.state.photos.map((photo, index) => <Image src={photo} visible={(index === this.state.visibleIndex)} />)
            }
            <SliderNavigationContainer>
                {
                    this.state.photos.map((square, index) => <Square selected={index === this.state.visibleIndex} onClick={() => this.setState({visibleIndex: index, isChangingBlocked: true})} />)
                }
            </SliderNavigationContainer>
        </Container>
    }
}

export default IndexSlider;