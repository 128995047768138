import styled from 'styled-components';

const Container = styled.div`
  width: auto;
  font-size: 18px;
  ${props => props.bottomLine ? 'padding-bottom: 7px;' : ''}
  margin-top: 8px;
  ${props => props.bottomLine ? 'margin-bottom: 8px;' : ''}
  position: relative;
  color: #153128;
  cursor: pointer;
  margin-right: 40px;
  float: left;
`;

const BottomLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 2px;
  background-color: #c9a386;
`

const SelectableTextItem = props => {
    return (
        <Container bottomLine={props.bottomLine}>
            {props.children}
            {props.bottomLine ? <BottomLine /> : ''}
        </Container>
    )
}

export default SelectableTextItem;