import React from "react";
import styled from 'styled-components';

const SeparationLine = styled.div`
  width: ${props => props.width ? props.width : "30"}px;
  height: 2px;
  margin-top: ${props => props.marginTop ? props.marginTop : "0"}px;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "0"}px;
  background-color: #c9a386;
`

export default SeparationLine;