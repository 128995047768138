import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "./logo_gold.svg"
import logoMobile from "./logo_mobile.svg"
import instagramIcon from "./instagram_icon.svg"
import facebookIcon from "./facebook_icon.svg"
import Hamburger from 'hamburger-react'
import {useState} from "react";

const Container = styled.div`
  width: 100%;
  height: 120px;
  background-color: #153128;
  position: relative;

  @media (max-width: 1100px) {
    height: 60px;
    ${props => props.isHamburgerMenuOpened ? "height: 120px;" : ""}
    }
`

const SubContainer = styled.div`
  width: calc(100% - 0px);
  max-width: calc(1600px + 2*300px);
  margin-bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-color: #153128;
  color: #c9a386;
  padding: 0 300px;
  float: left;

  @media (max-width: 1685px) {
    padding: 0 180px;
    max-width: calc(1600px + 2*180px);
  }
  @media (max-width: 1455px) {
    padding: 0 100px;
    max-width: calc(1600px + 2*100px);
  }

  @media (max-width: 1100px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 60px;
  }
`;

const Logo = styled.div`
  width: 180px;
  height: 100%;
  padding-top: 34px;
  text-align: center;
  float: left;

  @media (max-width: 1455px) {
    width: 170px;
  }

  @media (max-width: 1100px) {
    padding-top: 0px;
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    width: 92px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const LogoMobile = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 33px;
  height: 100%;
  padding-top: 20px;
  text-align: center;
  float: left;



  @media (min-width: 601px) {
    display: none;
  }
`


const LeftSection = styled.div`
  width: calc(50% - 180px/2);
  height: 100%;
  float: left;

  @media (max-width: 1100px) {

  }
`

const RightSection = styled.div`
  width: calc(50% - 180px/2);
  height: 100%;
  float: right;

  @media (max-width: 1100px) {
    display: none;
  }
`

const NavigationBar = styled.div`
  width: 100%;
  height: 50px;
  margin-top: calc(120px/2 - 50px/2);
  margin-bottom: calc(120px/2 - 50px/2);
  float: left;
  padding-top: 25px;
  transform: translateY(-12.5px);

  @media (max-width: 1100px) {
    margin-top: 0;
    width: 300px;
    position: absolute;
    top: 9px;
    left: 30px;
  }
`

const NavigationItemLeft = styled.div`
  width: auto;
  height: calc(100% + 6px);
  font-size: 18px;
  padding: 0 46px;
  padding-top: 3px;
  border-right: 1.5px solid #c9a386;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
  position: relative;
  float: left;

  @media (max-width: 1455px) {
    font-size: 17px;
    padding: 0 32px;
  }

  @media (max-width: 1100px) {
    height: auto;
    padding: 0 16px;
  }
`

const NavigationItemRight = styled.div`
  width: auto;
  height: 100%;
  font-size: 18px;
  padding: 0 46px;
  border-left: 1.5px solid #c9a386;
  &:first-child {
    padding-right: 0;
  }
  &:last-child {
    border-left: none;
  }
  position: relative;
  float: right;

  @media (max-width: 1455px) {
    font-size: 17px;
    padding: 0 32px;
  }
`

const BottomLine = styled.div`
  position: absolute;
  bottom: -4px;
  left: 50%;
  ${props => props.last ? "left: calc(50% + 23px);" : ""}
  transform: translateX(-50%);
  width: 30px;
  border-bottom: 1.5px solid #c9a386;
  
`

const SocialMediaImg = styled.img`
  height: 23px;

  @media (max-width: 1100px) {
    height: 12px;
  }
`

const HamburgerContainer = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
  position: absolute;
  top: 7px;
  right: 30px;
  width: 32px;
  height: 32px;
`

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 60px;
  background-color: rgba(21, 49, 40, 0.8);
  background-color: rgba(16, 38, 31, 0.9);
  z-index: 10;
  text-align: center;
  padding-top: 17px;

  @media (min-width: 1100px) {
    display: none;
  }  
`

const MobileMenuItem = styled.div`
  width: 33%;
  height: auto;
  font-size: 16px;
  padding: 3px 23px;
  border-right: 1.5px solid #c9a386;
  color: #c9a386;

  &:last-child {
    border-right: none;
  }
  position: relative;
  float: left;

  @media (max-width: 335px) {
    font-size: 15px;
  }

  @media (max-width: 305px) {
    padding: 0 11px;
  }
  @media (max-width: 290px) {
    font-size: 14px;
    border-right: none;
  }  
`


const Header = props => {
    const [isOpen, setOpen] = useState(false);
    return (
        <Container isHamburgerMenuOpened={isOpen}>
            <SubContainer>
                <LeftSection>
                    <NavigationBar>
                        <NavigationItemLeft><SocialMediaImg src={instagramIcon} /></NavigationItemLeft>
                        <NavigationItemLeft><SocialMediaImg src={facebookIcon} /></NavigationItemLeft>
                    </NavigationBar>
                </LeftSection>

                    <Logo>
                        <Link to="/"><img src={logo} /></Link>
                    </Logo>
                    <LogoMobile>
                        <Link to="/"><img src={logoMobile} /></Link>
                    </LogoMobile>

                <RightSection>
                    <NavigationBar>
                        <NavigationItemRight>{props.selectedIndex === 2 ? <BottomLine last /> : ''}<Link to="/oferta">OFERTA</Link></NavigationItemRight>
                        <NavigationItemRight>{props.selectedIndex === 1 ? <BottomLine /> : ''}<Link to="/kontakt">KONTAKT</Link></NavigationItemRight>
                        <NavigationItemRight>{props.selectedIndex === 0 ? <BottomLine /> : ''}<Link to="/o-nas">O NAS</Link></NavigationItemRight>
                    </NavigationBar>
                </RightSection>

                <HamburgerContainer>
                    <Hamburger size={24} distance="lg" toggled={isOpen} toggle={setOpen} />
                </HamburgerContainer>
            </SubContainer>

            {
                isOpen
                ?
                    <MobileMenuContainer>
                        <MobileMenuItem>{props.selectedIndex === 0 ? <BottomLine /> : ''}<Link to="/o-nas">O NAS</Link></MobileMenuItem>
                        <MobileMenuItem>{props.selectedIndex === 1 ? <BottomLine /> : ''}<Link to="/kontakt">KONTAKT</Link></MobileMenuItem>
                        <MobileMenuItem>{props.selectedIndex === 2 ? <BottomLine /> : ''}<Link to="/oferta">OFERTA</Link></MobileMenuItem>
                    </MobileMenuContainer>
                :
                    ''
            }

        </Container>
    )
}

export default Header;