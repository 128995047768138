import React, {Component} from "react";
import styled from 'styled-components';
import OfferElement from "../OfferElement/OfferElement";
import OfferEmptyElement from "../OfferEmptyElement/OfferEmptyElement";
import OfferNavigation from "../OfferNavigation/OfferNavigation";
import Clearfix from "../../Clearfix/Clearfix";
import config from '../../../config.json'
import OfferElementTall from "../OfferElementTall/OfferElementTall";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  //background-color: gray;
  float: left;
`

const SubContainer = styled.div`
  margin: 0 -30px;

  @media (max-width: 600px) {
    margin: 0 -15px;
  }
`


class OfferBelowProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {products: []};
    }

    componentDidMount() {
        fetch(`${config.backendUrl}/api/v1/random_products?ignoreProductId=${this.props.ignoreProductId}`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    // nothing
                } else {

                    const { products } = res;
                    let newProductsArray = [];
                    for (let i = 0; i < products.length; i++) {
                        newProductsArray.push({
                            productId: products[i].productId,
                            name: products[i].name,
                            photoThumbnail: products[i].photoThumbnail,
                            making: products[i].making
                        });
                    }
                    this.setState({products: newProductsArray});
                }

            })
            .catch(err => console.log(err))
    }

    render() {
        return <Container>
            <SubContainer>
                {
                    this.state.products.map((product, index) => <OfferElement data={product} isHiddenOnMobile={index > 1} />)
                }
            </SubContainer>
        </Container>
    }
}

export default OfferBelowProductList;