import styled from 'styled-components';
import Header from "../../components/Header/Header";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import TextHeader from "../../components/TextHeader/TextHeader";
import Clearfix from "../../components/Clearfix/Clearfix";
import Footer from "../../components/Footer/Footer";
import { React, useEffect } from "react";
import { useParams } from "react-router";
import Product from "../../components/Product/Product";
import {Link, useNavigate} from "react-router-dom";

const ProductView = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <>
        <Header selectedIndex={2} />
        <ContentContainer>
            <TextHeader bottomArrowBack style={{marginTop: 0, marginBottom: 26}}>
                <span onClick={() => navigate(-1)} style={{cursor: "pointer"}}>POWRÓT</span>
            </TextHeader>
            <Product productId={productId} key={"product-" + productId} />
        </ContentContainer>
        <Footer selectedIndex={2} />
    </>
}

export default ProductView;