import styled from 'styled-components';
import Header from "../../components/Header/Header";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import TextHeader from "../../components/TextHeader/TextHeader";
import Footer from "../../components/Footer/Footer";
import React, {Component} from "react";
import IndexSlider from "../../components/Index/IndexSlider/IndexSlider";
import OfferList from "../../components/Offer/OfferList/OfferList";
import IndexContactBanner from "../../components/Index/IndexContactBanner/IndexContactBanner";
import OfferElement from "../../components/Offer/OfferElement/OfferElement";
import OfferShortList from "../../components/Offer/OfferShortList/OfferShortList";
import OfferElementTall from "../../components/Offer/OfferElementTall/OfferElementTall";
import config from "../../config.json";

class IndexView extends Component {
    constructor(props) {
        super(props);
        this.state = {gallery1Products: [], gallery2Products: []};
    }

    componentDidMount() {
        this.loadGallaryProducts();
    }

    loadGallaryProducts() {
        fetch(`${config.backendUrl}/api/v1/index_views`)
            .then(res => res.json())
            .then(res => {
                if (res.status === "error") {
                    // nothing
                } else {
                    this.setState({gallery1Products: res.gallery1, gallery2Products: res.gallery2});
                }

            })
            .catch(err => console.log(err))
    }

    render() {
        return <>
            <Header/>
            <IndexSlider/>
            <ContentContainer>
                <TextHeader bottomLine style={{marginTop: 0}}>POLECANE</TextHeader>
                {this.state.gallery1Products.length > 0 ? <OfferShortList productIds={this.state.gallery1Products} /> : '' }
            </ContentContainer>
            <IndexContactBanner />
            <ContentContainer style={{marginTop: 0}}>
                {this.state.gallery2Products.length > 0 ? <OfferShortList productIds={this.state.gallery2Products} tall /> : '' }
            </ContentContainer>

            <Footer/>
        </>
    }
}

export default IndexView;